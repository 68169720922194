export class BootstrapManager {
    public static async handleCollapses() {
        const matchingElements = document.querySelectorAll(`[data-bs-toggle="collapse"]`);

        if (matchingElements.length > 0) {
            await import("bootstrap/js/dist/collapse").then(x => {
                matchingElements.forEach(e => new x.default(e));
            });
        }
    }

    public static async handleTooltips() {
        const matchingElements = document.querySelectorAll(`[data-bs-toggle="tooltip"]`);

        if (matchingElements.length > 0) {
            await import("bootstrap/js/dist/tooltip").then(x => {
                matchingElements.forEach(e => new x.default(e));
            });
        }
    }

    public static async handleModals() {
        const matchingElements = document.querySelectorAll(`[data-bs-toggle="modal"]`);

        if (matchingElements.length > 0) {
            await import("bootstrap/js/dist/modal").then(x => {
                matchingElements.forEach(e => new x.default(e));
            });
        }
    }

    public static async handleDropdowns() {
        const matchingElements = document.querySelectorAll(`[data-bs-toggle="dropdown"]`);

        if (matchingElements.length > 0) {
            await import("bootstrap/js/dist/dropdown").then(x => {
                matchingElements.forEach(e => new x.default(e));
            });
        }
    }
}