export class TableManager {
    static async handleTable() {
        const tableContainerElement: HTMLDivElement = document.querySelector("[data-wh-table]");

        if (tableContainerElement) {
            await import("../component/table.component").then(x => {
                new x.TableComponent(tableContainerElement);
            });
        }
    }

    static async placeTableContent() {
        const targetTableBody = document.getElementById('targetTableBody');

        if (!targetTableBody) {
            return;
        }

        const sourceTableBody = document.getElementById('sourceTableBody');

        if (!targetTableBody) {
            return;
        }

        targetTableBody.innerHTML = sourceTableBody.innerHTML;
        sourceTableBody.remove();
        console.log("Table content placed.")
        return;
    }
}