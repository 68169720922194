import "./scss/main.scss";
import { BootstrapManager } from "./ts/manager/bootstrap.manager";
import { MessageManager } from "./ts/manager/message.manager";
import { ModalManager } from "./ts/manager/modal.manager";
import { PageLoaderManager } from "./ts/manager/page-loader.manager";
import { TableManager } from "./ts/manager/table.manager";

document.addEventListener("DOMContentLoaded", () =>
    mfc('Init all JS', () => Promise.all([
        mfc('Spinner on', () => PageLoaderManager.setSpinnerVisibility(true))
            .then(() => mfc('Place table content', () => TableManager.placeTableContent())
                .then(() => mfc('First level promises', () => handleFirstLevelPromises())
                    .then(() => mfc('Finish promises', () => finishPromises()))))
    ])));

async function handleFirstLevelPromises() {
    await Promise.all([
        mfc('Bootstrap collapses', () => BootstrapManager.handleCollapses()),
        mfc('Bootstrap dropdowns', () => BootstrapManager.handleDropdowns()),
        mfc('Bootstrap modals', () => BootstrapManager.handleModals()),
        mfc('Bootstrap tooltips', () => BootstrapManager.handleTooltips()),
        mfc('Modal remove', () => ModalManager.handleModalsRemove()),
        mfc('Table logic', () => TableManager.handleTable())])
}

async function finishPromises() {
    await MessageManager.handleMessages();
    await PageLoaderManager.setSpinnerVisibility(false);
}

async function mfc(functionName: string, functionToMeasure: () => void) {
    console.log(`===========================================`);
    console.log(`${functionName} stared.`);
    console.log(`===========================================`);

    const startTime = Date.now();

    await functionToMeasure();

    const endTime = Date.now();

    console.log(`===========================================`);
    console.log(`${functionName} finished in time: ${endTime - startTime} ms.`);
    console.log(`===========================================`);
}