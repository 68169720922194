export class ModalManager {
    public static async handleModalsRemove() {
        const matchingElements = document.querySelectorAll("a[wh-modal-remove]");

        if (matchingElements.length > 0) {
            await import("../component/modal-remove.component").then(x => {
                matchingElements.forEach((anchorEl: HTMLAnchorElement) => new x.ModalRemoveComponent(anchorEl))
            });
        }
    }
}